<template>
  <ul class="menu-nav">
    <!-- <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-architecture-and-city"></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link> -->

    <!-- <li class="menu-section">
      <h4 class="menu-text">Components</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li> -->

    <li
      v-for="(item, index) in itemsMenu"
      :key="index"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{
        'menu-item-open': hasActiveChildren(item.route),
      }"
    >
       <router-link
        :to="item.route"
        v-slot="{ href, navigate }"
        v-if="!item.subItems"
      >
        <a :href="href" class="bbi menu-link menu-toggle" @click="navigate">
          <i
            class="  menu-icon"
            :class="{ [item.iconClass]: true }"
          ></i>
          <span class="  menu-text text-uppercase font-weight-bold ">{{
            item.title
          }}</span>
        </a>
      </router-link>
      <a       v-show="lista_permissoes_filial[item.permissao]"  v-if="item.subItems" href="#" class=" bbi menu-link   menu-toggle" >
        <i class="menu-icon" :class="{ [item.iconClass]: true }"></i>
        <span class="menu-text"> {{ item.title }} </span>
        <i     class="menu-arrow"></i>
      </a>
      <div class="menu-submenu menu-submenu-classic menu-submenu-right">
        <ul class="menu-subnav">
          <router-link
            :to="subItem.route"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-for="(subItem, index) in item.subItems"
            :key="index"
            
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a
                v-show="lista_permissoes_filial[subItem.permissao]"
                :href="href"
                class="menu-link"
                @click="navigate"
              >
                <i class="menu-bullet menu-bullet-dot"><span></span></i>
                <span class="menu-text"> {{ subItem.title }} </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </ul>
</template>


<script>
import sideMenu from "@/core/router/sideMenu";

export default {
  name: "KTMenu",

  data() {
    return {
      itemsMenu: sideMenu,
      sede_id: "0",
    };
  },
  created() {
    this.$store.dispatch("sistema/listar_filiais_autorizadas");
  },
  watch: {
    sede_id: function () {
      this.$store.dispatch("vincularSede", this.sede_id);
    },
  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_filiais_autorizadas() {
      return this.$store.state.sistema.lista_filiais_autorizadas;
    },
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>

<style>
.bbi .menu-icon {
  -webkit-text-stroke-width: 0.9px;
  -webkit-text-stroke-color: rgb(116, 114, 7);
  letter-spacing: 1px;
}
</style>
