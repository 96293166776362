export default [

    {
        title: "Perfil",
        botoes: [
            {
                title: "Criar",
                route: "/createPerfil",
                permissao: "c_Perfil",
            },
            {
                title: "Listar",
                route: "/perfil",
                permissao: "ra_Perfil",
            },
        ],
    },
    {
        title: "Setor",
        botoes: [
            {
                title: "Criar",
                route: "/createSetor",
                permissao: "c_Setor",
            },
            {
                title: "Listar",
                route: "/setor",
                permissao: "ra_Setor",
            },
        ],
    },


    {
        title: "Permissoes",
        botoes: [
            {
                title: "Criar",
                route: "/createRule",
                permissao: "c_Role",
            },
            {
                title: "Listar",
                route: "/permissoes",
                permissao: "ra_Role",
            },
        ]
    },
    {
        title: "Empresa",
        botoes: [
            {
                title: "Criar",
                route: "/createEmpresa",
                permissao: "ra_empresa",
            },
            {
                title: "Listar",
                route: "/empresa",
                permissao: "ra_empresa",
            },
        ]
    },
    {
        title: "Filial",
        botoes: [
            {
                title: "Criar",
                route: "/createFilial",
                permissao: "c_Filial",
            },
            {
                title: "Listar",
                route: "/filial",
                permissao: "ra_Filial",
            },
        ]
    },

    {
        title: "Usuarios",
        botoes: [
            {
                title: "Criar",
                route: "/createFuncionario",
                permissao: "c_Usuario",
            },
            {
                title: "Listar",
                route: "/gerenFuncionarios",
                permissao: "ra_Usuario",
            },
        ],
    },

    {
        title: "Tipo Protocolo",
        botoes: [
            {
                title: "Criar",
                route: "/createTipoProtocolo",
                permissao: "c_TipoProtocolo",
            },
            {
                title: "Listar",
                route: "/tipoProtocolo",
                permissao: "ra_TipoProtocolo",
            },
        ],
    },

    {
        title: "Protocolos",
        botoes: [
            {
                title: "Criar",
                route: "/createProtocolo",
                permissao: "c_TipoProtocolo",
            },
            {
                title: "Listar",
                route: "/dashboard",
                permissao: "ra_TipoProtocolo",
            },
        ],
    },
    {
        title: "Contrato Serviços",
        botoes: [
            {
                title: "Criar",
                route: "/createContratoServico",
                permissao: "c_ContratoServicos",
            },
            {
                title: "Listar",
                route: "/contratoServico",
                permissao: "ra_ContratoServicos",
            },
        ],
    },
    
   
    
    // {
    //     title: "Imoveis",
    //     botoes: [
    //         {
    //             title: "Criar",
    //             route: "/createImovel",
    //             permissao: "c_Imovel",
    //         },
    //         {
    //             title: "Listar",
    //             route: "/imovel",
    //             permissao: "ra_Imovel",
    //         },
    //     ],
    // },
    // {
    //     title: "Contratos",
    //     botoes: [
    //         {
    //             title: "Criar",
    //             route: "/createContrato",
    //             permissao: "c_Contrato",
    //         },
    //         {
    //             title: "Listar",
    //             route: "/contrato",
    //             permissao: "ra_Contrato",
    //         },
    //     ],
    // },


  
    {
        title: "Planos",
        botoes: [
            {
                title: "Criar",
                route: "/createProduto",
                permissao: "c_Produto",
            },
            {
                title: "Listar",
                route: "/produto",
                permissao: "ra_Produto",
            },
        ],
    },
    {
        title: "Turmas",
        botoes: [
            {
                title: "Criar",
                route: "/createTurma",
                permissao: "c_Produto",
            },
            {
                title: "Listar",
                route: "/turma",
                permissao: "ra_Produto",
            },
        ],
    },
    {
        title: "Contrato serviços",
        botoes: [
            {
                title: "Criar",
                route: "/createContatoServicos",
                permissao: "c_Produto",
            },
            {
                title: "Listar",
                route: "/contratoServicos",
                permissao: "ra_Produto",
            },
        ],
    },


    {
        title: "Tipos de Produtos",
        botoes: [
            {
                title: "Criar",
                route: "/createTipoProduto",
                permissao: "c_TipoProduto",
            },
            {
                title: "Listar",
                route: "/tipoProduto",
                permissao: "ra_TipoProduto",
            },
        ],
    },

    {
        title: "Modos de Pagamento",
        botoes: [
            {
                title: "Criar",
                route: "/createModoPagamento",
                permissao: "c_FinanModoPagamento",
            },
            {
                title: "Listar",
                route: "/modoPagamento",
                permissao: "ra_FinanModoPagamento",
            },
        ],
    },

    {
        title: "Formas de Pagamento",
        botoes: [
            {
                title: "Criar",
                route: "/createFormaPagamento",
                permissao: "c_FinanFormaPagamento",
            },
            {
                title: "Listar",
                route: "/formaPagamento",
                permissao: "ra_FinanFormaPagamento",
            },
        ],
    },


    {
        title: "Criar",
        botoes: [
            {
                title: "Criar",
                route: "/createFinanPedidoRecebimento",
                permissao: "c_FinanFormaPagamento",
            },
            {
                title: "Listar",
                route: "/finanPedidoRecebimento",
                permissao: "ra_FinanFormaPagamento",
            },
        ],
    },

    {
        title: "Pedidos de Recebimento",
        botoes: [
            {
                title: "Criar",
                route: "/createFinanPedidoRecebimento",
                permissao: "c_TipoProtocolo",
            },
            {
                title: "Listar",
                route: "/listaFinanPedidoRecebimento",
                permissao: "ra_TipoProtocolo",
            },
        ],
    },
    {
        title: "Serviços",
        botoes: [
            {
                title: "Criar",
                route: "/createServico",
                permissao: "c_TipoProtocolo",
            },
            {
                title: "listar",
                route: "/servicos",
                permissao: "ra_TipoProtocolo",
            },
        ],
    },
    
    




    

    


  




];
